var rb = rb || {};
(function($) {
var easterEgg1 = {
  // Boring variables
  internal: {
    particles: [],
    animationTimer: null,
    previousTime: null,
    lastParticleTime: null,
    particlesWaiting: 0,
    preloadedImages: []
  },

  // Interesting variables
  options: {
    magicSearchTerms: [],
    imageURL:'',
    imageCount: 100,
    imagesPerSecond: 10,
    imageSizeMin: 176,
    imageSizeMax: 176,
    gravity: 9.807,
    wind: 0,
    spinMin: -90,
    spinMax: 90,
    animationFramesPerSecond: 40
  },

  // Initialize the easter egg
  init: function() {
    // Pre-load the image:
    if (rb?.endeca?.enable_animation_easter_egg === '1' && easterEgg1.options.imageUrl) {
      easterEgg1.internal.preloadedImages[easterEgg1.options.imageUrl] = $('<img />').attr('src', easterEgg1.options.imageUrl);
      $.get(easterEgg1.options.imageUrl);
    }
    //listen for input search terms: 
    easterEgg1.startWatchingForSearchTerm();

    var elUrl = location.toString();
    var searchResult;
    if (elUrl.indexOf('&search=') !== -1) {//if we are on search results page
      // 1) check searched term & trigger animation if appropriate:
      searchResult = elUrl.split('&search=')[1].replace('+', ' ');
      for (var i = 0; i < easterEgg1.options.magicSearchTerms.length; i++) {
        if (easterEgg1.options.magicSearchTerms[i] === searchResult) {
          easterEgg1.trigger();
          break;
        }
      }
      // 2) listen to results page search input & trigger animation if appropriate:
      var $searchResultsInput = $('.search-form .search-term');
      $searchResultsInput.on('input', function() {
        var searchResultsText = ($searchResultsInput.val() || '').toLowerCase();
        if (easterEgg1.options.magicSearchTerms.includes(searchResultsText)) {
          easterEgg1.trigger();
        } else {
          easterEgg1.stop();
        }
      });
    }
  },

  // Begin monitoring the search input box and watching for the magic text
  startWatchingForSearchTerm: function() {
    var $searchInput = $('#perlgem-search-form #search');
    $searchInput.on('input', function() {
      var searchText = ($searchInput.val() || '').toLowerCase();
      if (easterEgg1.options.magicSearchTerms.includes(searchText)) {
        easterEgg1.trigger();
      } else {
        easterEgg1.stop();
      }
    });
  },

  // Trigger the effect
  trigger: function() {
    // First animation frame
    if (easterEgg1.internal.animationTimer == null) {
      easterEgg1.internal.particlesWaiting = easterEgg1.options.imageCount;
      window.requestAnimationFrame(easterEgg1.animate);
    }
  },

  // Stop the effect
  stop: function() {
    if (easterEgg1.internal.animationTimer) {
      clearTimeout(easterEgg1.internal.animationTimer);
      easterEgg1.internal.animationTimer = null;
      easterEgg1.internal.previousTime = null;

      for (var i = easterEgg1.internal.particles.length - 1; i >= 0; i--) {
        var particle = easterEgg1.internal.particles[i];
        particle.element.remove();
      }
      easterEgg1.internal.particles = [];
    }
  },

  // Move along the animation
  animate: function(time) {
    // Measure the window so we can tell when the particles go below the bottom
    var windowHeight = $(window).height();
    var windowWidth = $(window).width();

    // How much time has passed since the previous animation frame?
    if (easterEgg1.internal.previousTime == null) {
      easterEgg1.internal.previousTime = time - ((1 / easterEgg1.options.imagesPerSecond) * 2000);
      easterEgg1.internal.lastParticleTime = easterEgg1.internal.previousTime;
    }
    var elapsedSeconds = (time - easterEgg1.internal.previousTime) / 1000; // Convert from milliseconds.

    // Add particles
    var particlesToAdd = Math.round(((time - easterEgg1.internal.lastParticleTime) / 1000) * easterEgg1.options.imagesPerSecond);
    particlesToAdd = Math.min(particlesToAdd, easterEgg1.internal.particlesWaiting);
    if (particlesToAdd > 0) {
      if (easterEgg1.internal.preloadedImages[easterEgg1.options.imageUrl] == null) {
        easterEgg1.internal.preloadedImages[easterEgg1.options.imageUrl] = $('<img />').attr('src', easterEgg1.options.imageUrl);
      }
      easterEgg1.internal.particlesWaiting = Math.max(0, easterEgg1.internal.particlesWaiting - particlesToAdd);
      easterEgg1.internal.lastParticleTime = time;
      for (var k = 0; k < particlesToAdd; k++) {
        // Generate a random position, rotation, and size
        var size = easterEgg1.options.imageSizeMin + (Math.random() * (easterEgg1.options.imageSizeMax - easterEgg1.options.imageSizeMin));
        var minX = easterEgg1.options.wind > 0 ? 0 - easterEgg1.options.wind * 20 : 0;
        var maxX = easterEgg1.options.wind < 0 ? windowWidth + easterEgg1.options.wind * 20 : windowWidth;
        var newParticle = {
          x: minX + (Math.random() * (maxX - minX)),
          y: easterEgg1.options.gravity >= 0 ? -size : windowHeight + size,
          size: size,
          rotation: Math.random() * 360,
          velocityY: 0,
          spin: easterEgg1.options.spinMin + (Math.random() * (easterEgg1.options.spinMax - easterEgg1.options.spinMin))
        };

        // Create a visible element
        newParticle.element = easterEgg1.internal.preloadedImages[easterEgg1.options.imageUrl].clone();
        $('body').append(newParticle.element);
        easterEgg1.internal.particles.push(newParticle);
      }
    }

    // Move particles
    for (var j = 0; j < easterEgg1.internal.particles.length; j++) {
      // Calculate a new position and rotation
      var particle = easterEgg1.internal.particles[j];
      particle.x += easterEgg1.options.wind * 10 * elapsedSeconds;
      particle.velocityY += easterEgg1.options.gravity * elapsedSeconds;
      particle.y += particle.velocityY;
      particle.rotation += particle.spin * elapsedSeconds;

      // Update the visible image
      particle.element.attr('style', 'display:block !important; position:fixed; width:auto; z-index:100000; left:' + particle.x + 'px;top:' + particle.y + 'px;transform:translate(-50%,-50%) rotate(' + particle.rotation + 'deg);height:' + particle.size + 'px;backface-visibility:hidden;');
    }

    // Remove particles that go off the bottom
    for (var l = easterEgg1.internal.particles.length - 1; l >= 0; l--) {
      var particle2 = easterEgg1.internal.particles[l];
      var isBelowTheBottom = easterEgg1.options.gravity >= 0 ? (particle2.y - particle2.size) > windowHeight : (particle2.y + particle2.size) < 0;
      if (isBelowTheBottom) {
        particle2.element.remove();
        easterEgg1.internal.particles.splice(l, 1);
      }
    }

    // We are done when there are no particles left
    var stillAnimating = easterEgg1.internal.particles.length > 0;
    if (stillAnimating && easterEgg1.options.animationFramesPerSecond > 0) {
      // Schedule the next animation frame
      easterEgg1.internal.previousTime = time;
      easterEgg1.internal.animationTimer = setTimeout(function() {
        window.requestAnimationFrame(easterEgg1.animate);
      }, Math.max(1, 1000 / easterEgg1.options.animationFramesPerSecond));
    } else {
      // Done, don't waste time animating
      easterEgg1.internal.previousTime = null;
      easterEgg1.internal.animationTimer = null;
    }
  }
};


  // Start initializing the easter egg when jQuery is ready
  if (rb?.endeca?.enable_animation_easter_egg === '1') { // if easter egg switched on via CMS       
    // use CMS-input search terms, if they exist:
    if (rb.endeca.animation_easter_egg_triggers) {
      easterEgg1.options.magicSearchTerms = rb.endeca.animation_easter_egg_triggers.toLowerCase().split(',');
    }
    // use CMS-input image, if it exists:
    if($('#kk_easter_egg_search_image').length){
      easterEgg1.options.imageUrl = $('#kk_easter_egg_search_image').attr('data-src');
    }
    // then init Easter Egg animation:    
    easterEgg1.init();
  }

})(jQuery);
